import { StoryRecord } from "@/types";
import PlaceholderV2 from "@/assets/placeholder-img.png";
import { getStoryAssetImageUrl } from "@/utils/url";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";

type Props = {
  story: StoryRecord;
  onClick: (id: number, story: StoryRecord) => void;
  index: number;
};

export const PagePreview = ({ story, onClick, index }: Props) => {
  if (!story.pages || story.pages.length === 0) return null;
  const previewPage = story.pages[0];
  const hasImage = !!previewPage.imageS3Key || !!previewPage.coverS3Key;
  const imageUrl = hasImage
    ? getStoryAssetImageUrl(
        previewPage.coverS3Key || previewPage.imageS3Key || "",
      )
    : PlaceholderV2;

  return (
    <div
      className="relative cursor-pointer rounded-xl select-none overflow-hidden h-full"
      onClick={() => onClick(index, story)}
    >
      <div className="w-full h-full">
        <ImageWithPlaceholder
          loading="lazy"
          src={imageUrl}
          placeholderSrc={PlaceholderV2}
          alt="story"
          className="rounded-md"
        />
      </div>

      {(previewPage.emoji || previewPage.text) && (
        <div className="bg-gradient-to-t from-black absolute bottom-0 p-4 flex flex-col gap-2 rounded-sm w-full">
          <p className="text-4xl">{previewPage.emoji}</p>
          <p className="text-xl text-white" style={{ wordBreak: "break-word" }}>
            {previewPage.text}
          </p>
        </div>
      )}
    </div>
  );
};

export default PagePreview;

import { StoryRecord } from "@/types";
import PagePreview from "../PagePreview";
import { useContext, useEffect } from "react";
import { createAmp } from "@/utils/amp";
import { ChevronLeft, ChevronRight } from "lucide-react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/virtual";
import { SwiperOptions } from "swiper/types";
import useAmpPlayer from "@/hooks/useAmpPlayer";
import { cn } from "@/utils/cn";
import { AmpContext } from "@/context/ampContext";
import { handleAmpPlayerOpenStoryEvent } from "@/analytics/events";
import CarouselSkeleton from "../CarouselSkeleton";

type Props = {
  collection: StoryRecord[];
};

export const Carousel = ({ collection }: Props) => {
  const { setCurrentSlide, currentSlide, playerRef } = useAmpPlayer();
  const {
    isFullScreen,
    setCurrentStory: setStory,
    isReady,
  } = useContext(AmpContext);

  const swiperOptions: SwiperOptions = {
    navigation: {
      nextEl: ".image-swiper-button-next",
      prevEl: ".image-swiper-button-prev",
      disabledClass: "swiper-button-disabled",
    },
    modules: [Navigation],
    slidesPerView: "auto",
    spaceBetween: 10,
  };

  useEffect(() => {
    const ampPlayer = playerRef.current;
    if (ampPlayer && ampPlayer.getStories().length === 0) {
      const pageIds = collection.map((story) => story.id);
      createAmp(pageIds, ampPlayer);
    }
  }, [collection, playerRef]);

  const handleClick = (id: number, story: StoryRecord) => {
    setStory(id);
    handleAmpPlayerOpenStoryEvent(story);
  };

  if (!isReady) return <CarouselSkeleton />;

  return (
    <div
      className={cn(
        "flex items-stretch h-full w-screen overflow-hidden",
        isFullScreen && "hidden",
      )}
    >
      <div className="overflow-hidden swiper-button image-swiper-button-prev cursor-pointer flex items-center hover:scale-110 active:scale-90 transition-all">
        <ChevronLeft className="size-10 opacity-50" />
      </div>
      <Swiper
        {...swiperOptions}
        onSlideChange={(e) => setCurrentSlide(e.activeIndex)}
        initialSlide={currentSlide}
        className="!m-0"
      >
        {collection.map((story, id) => {
          return (
            <SwiperSlide className="!w-[225px] h-[400px]" key={story.id}>
              <PagePreview story={story} index={id} onClick={handleClick} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="overflow-hidden swiper-button image-swiper-button-next cursor-pointer flex items-center hover:scale-110 active:scale-90 transition-all">
        <ChevronRight className="opacity-50 size-10" />
      </div>
    </div>
  );
};

export default Carousel;

import { useState, useEffect } from "react";
import { Skeleton } from "../Skeleton";

const CarouselSkeleton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="flex items-stretch gap-[10px] h-full w-screen">
      <Skeleton className="w-[225px] h-[400px]" />
      <Skeleton className="w-[225px] h-[400px]" />
      <Skeleton className="w-[225px] h-[400px]" />
      <Skeleton className="w-[225px] h-[400px]" />
    </div>
  );
};

export default CarouselSkeleton;

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

interface AmpContextType {
  currentStory: number | null;
  setCurrentStory: Dispatch<SetStateAction<number | null>>;
  currentSlide: number;
  setCurrentSlide: Dispatch<SetStateAction<number>>;
  isReady: boolean;
  setIsReady: Dispatch<SetStateAction<boolean>>;
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
}

export const AmpContext = createContext<AmpContextType>({
  currentStory: null,
  setCurrentStory: () => {},
  currentSlide: 0,
  setCurrentSlide: () => {},
  setIsReady: () => {},
  isReady: false,
  isFullScreen: false,
  setIsFullScreen: () => {},
});

interface AmpProviderProps {
  children: ReactNode | ReactNode[];
}

export const AmpProvider: React.FC<AmpProviderProps> = ({ children }) => {
  const [currentStory, setCurrentStory] = useState<number | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  return (
    <AmpContext.Provider
      value={{
        currentStory,
        setCurrentStory,
        currentSlide,
        setCurrentSlide,
        isReady,
        setIsReady,
        isFullScreen,
        setIsFullScreen,
      }}
    >
      {children}
    </AmpContext.Provider>
  );
};

import { cn } from "@/utils/cn";
import React, { useState } from "react";
import { Skeleton } from "../Skeleton";

type ImageWithPlaceholderProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  placeholderSrc?: string;
};

const ImageWithPlaceholder = ({
  src,
  placeholderSrc,
  alt,
  className = "",
  ...props
}: ImageWithPlaceholderProps) => {
  const [imageStatus, setImageStatus] = useState<
    "loading" | "loaded" | "error"
  >("loading");

  const handleLoad = () => setImageStatus("loaded");
  const handleError = () => setImageStatus("error");

  return (
    <div className="relative w-full h-full">
      {imageStatus === "loading" && (
        <div className="absolute inset-0">
          <Skeleton className="w-full h-full" />
        </div>
      )}
      {imageStatus !== "error" && (
        <img
          {...props}
          src={src}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          className={cn(
            className,
            "w-full h-full object-cover",
            imageStatus === "loaded" ? "block" : "invisible",
          )}
        />
      )}
      {imageStatus === "error" && placeholderSrc && (
        <img
          src={placeholderSrc}
          alt={alt}
          className={`${className} w-full h-full object-cover`}
        />
      )}
    </div>
  );
};

export default ImageWithPlaceholder;
